<template>
  <module-list-layout :createAction="false"  :header="false" :module="module" v-if="amReady"  style="width: 1200px !important;" class="mt-3">
    <h4>Labels</h4>
    <exit-button></exit-button>
    <!--<el-dialog :visible.sync="dialogVisible" title="" top="3vh" width="1200px" custom-class="">
    </el-dialog>-->

      <el-row>
        <el-col :class="$langConfig.cssClass" style="width: 220px;">
          <dg-text-field
              style="max-width: 400px"
              class="mt-1"
              :field_span="24"
              :label_span="0"
              field_mark=""
              :defaultValue="userSearch"
              :translateLabel="false"
              label=""
              placeholder="search here"
              @amUpdated="userSearchUpdated"
              :liveUpdate="true"></dg-text-field>
        </el-col>
        <el-col :class="$langConfig.cssClass" style="width: 220px;margin-left: 20px;">
        <dg-select-field
            @amUpdated="(p,v) => { accountingTypeChanged(v)}"
            :field_span="11"
            :label_span="13"
            :options="[...dropdownTaxReportingPeriods, {'label': 'All', 'value': '-all-'}]"
            :defaultValue="selectedAccountType"
            :translateLabel='false'
            :translateLabels="false"
            field_mark=""
            label="Accounting Type"
            name="account_accounting_type_id"
            placeholder=""
            rules=""></dg-select-field>
        </el-col>

        <el-col :class="$langConfig.cssClass" style="width: 120px;margin-left: 10px;">
          <dg-select-field
              :field_span="13"
              :label_span="11"
              :options="dropdown12Month"
              @amUpdated="(p, val) => {monthChanged(val)}"
              :translateLabel='false'
              :translateLabels="false"
              defaultValue="1"
              field_mark=""
              label="Month"
              name="month"
              placeholder=""
              rules=""></dg-select-field>
        </el-col>
        <el-col style="width: 100px;float: right">
          <el-button size="small" @click="openLabelLayout">Label Layout</el-button>
        </el-col>
      </el-row>

    <el-table ref="dataTable" :data="tableData" :cell-class-name="setCellClass"  style="width: 100%;height: 600px" @selection-change="handleTableSelectionChange"
              :row-class-name="tableRowClassName">
      <el-table-column
          type="selection"
          width="30">
      </el-table-column>
      <el-table-column
          label="Business Name"
          prop="business_name">
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="deleteSelectedDialog" center title="Warning" width="420px" :append-to-body="true">
      <div align="center" class="mt-2 mb-2">Are you sure you want to remove all records ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteSelectedDialog = false" size="small">Cancel</el-button>
        <el-button @click="removeSelectedApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible.sync="deleteDialogVisible" center title="Warning" width="420px">
      <div align="center" class="mt-2 mb-2">You want to remove this record ?</div>
      <span class="dialog-footer" slot="footer">
        <el-button @click="deleteDialogVisible = false" size="small">Cancel</el-button>
        <el-button @click="removeActionApi" size="small" type="danger">Yes</el-button>
      </span>
    </el-dialog>
  </module-list-layout>
</template>

<script>
import {chunkArray} from 'devegram-javascript-util'
import module from './../index'
import jsonTaxReportingPeriods from "@/json/dropdown/TaxReportingPeriods.json";
import json12month from "@/json/dropdown/12Month.json";
export default {
  mixins: [$mixins['listView']],
  components: {
    'accounting-notes': () => import('@/modules/Accounting/Note/form/View'),
    'exit-button': () => import('@/components/exit-button/View')
  },
  computed: {
    ...$mapState(module.info.fullName + '-api', ['pageChunkSize']),
  },
  beforeMount() {
    this.currentPage = parseInt(this.$route.params.listPage)
    this.accountingTypeChanged('-all-')
  },
  methods: {
    monthChanged (val) {
      if (val == this.selectedMonth) return
      this.selectedMonth = val
    },
    accountingTypeChanged (val) {
      if (val == this.selectedAccountType) return ;
      this.selectedAccountType = val
      this.getEntityListData(this.currentPage,  () => {
        this.$refs['dataTable'].toggleAllSelection()
      })
    },
    openLabelLayout () {
      //this.dialogVisible = true
      let pdfMake = require('pdfmake/build/pdfmake.js');
      let pdfFonts = require('pdfmake/build/vfs_fonts.js');
      pdfMake.vfs = pdfFonts.pdfMake.vfs;

      let currentYear = new Date().getFullYear();
      let currentMonthName = new Date(currentYear, this.selectedMonth, 1).toLocaleString('default', {month: 'short'});


      let labels = []
      let data = this.getSelectedClientsIds()

      let chunkedData = chunkArray(data, 2);

      let row = []
      for(let chunk in chunkedData) {
        row = []
        for(let client in chunkedData[chunk]) {

          let t1 = {text: chunkedData[chunk][client].business_name +  '\n', fontSize: 13, bold: true}
          let t3 = {text: ''+ (chunkedData[chunk][client].tax_hst_reporting_method_label).toString().replace('fi', 'f i') + '\n\n', style: 'accountingType'}
          let t4 = {text: 'Missing any: \n\n', style: 'dashedSeparator'}
          let t5 = {text: 'Bank Statement: Yes___  Sales Yes___\n\n', style: 'dashedSeparator'}
          let t6 = {text: currentMonthName + ' ' + currentYear, fontSize: 21, style: 'dashedSeparator'}

          let layout = {
            text:  [t1, t3, t4, t5, t6],
            alignment: 'center'
          }

          /*if (labels.length != 0 && (labels.length) % 5 == 0) {
            layout.pageBreak = 'before'
          }*/

          row.push(layout)
        }

        let rows = []
        rows.push(row)
        labels.push({
          style: 'tableExample',
          table: {
            headerRows: false,
            widths: [289, 289],
            heights: 145,
            body: rows
          },
          layout: 'noBorders'
        })
      }

      let counter = 0
      pdfMake.createPdf({
        pageBreakBefore: function(currentNode, followingNodesOnPage, nodesOnNextPage, previousNodesOnPage) {
          if (previousNodesOnPage.length == 16) return true
          return false;
        },
        // a string or { width: number, height: number }
        pageSize: 'A4',

        // by default we use portrait, you can change it to landscape if you wish
        pageOrientation: 'portrait',

        // [left, top, right, bottom] or [horizontal, vertical] or just a number for equal margins
        pageMargins: [ 11, 28, 11, 28],
        styles: {
          tableExample: {
            align:'center'
          },
          accountingType: {
            characterSpacing: 4
          },
          dashedSeparator: {
            lineHeight: .7
          }
        },
        defaultStyle: {
          columnGap: 20
        },
        content: labels
      }).print();
    },
    getSelectedClientsIds () {
      let selectedNodes = this.tableMultipleSelection
      let d = selectedNodes.map(node => node);
      return d
    },
    setCellClass({row, rowIndex}) {
      return 'custom-data-table-cell-business';
    },
    tableRowClassName ({row, rowIndex}) {
      let o = ''
      if (row.external_note  != '') o += 'app-gold-bg'

      return o
    },
    ...$mapActions(module.info.fullName + '-api', {getEntityList: 'list', updateEntity: 'update', multipleDelete: "multipleDelete"}),
    getEntityListData (page, onLoaded = false) {
      this.getEntityList({page: page, search: this.userSearch, account_status: '-all-',
        person_in_charge: '-all-',
        legal_structure: '-all-',
        business_type: '-all-',
        account_type: this.selectedAccountType,
        province: '-all-'}).then(response => {
        this.tableData = response.rows
        this.recordsCounter = response.count

        this.currentPage = page

        this.listFields = this.listConfig.fields || []
        if (this.reopenDialogAfterRefresh) {
          this.reopenDialogAfterRefresh = false
          this.dataToBind = response.rows[this.rowToEdit-1]
          this.dialogVisible = true
        }
        $vue.nextTick(() => {
          if (onLoaded) onLoaded(response)
        })
      })
    },
  },
  data() {
    return {
      selectedMonth: null,
      amReady: true,
      selectedAccountType: null,
      dropdownTaxReportingPeriods: jsonTaxReportingPeriods,
      selectedClientId: false,
      module: {...module},
      dropdown12Month: json12month,
      usersActions: [
        {
          label: ' View / Edit',
          icon: 'el-icon-edit-outline',
          action: 'editAction',
          notForProfiles: []
        },
        {
          label: 'Remove',
          icon: 'el-icon-delete',
          action: 'removeAction',
        },
      ]
    };
  }
}
</script>
